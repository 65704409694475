import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Modal from '../../components/Modal';
import Input from '../../components/Input';

import { Container, InputGroup, ModalButton } from './styles';
import { useGroups } from '../../hooks/groups';

interface IAddGroupFormData {
  name: string;
  code: string;
  start_date: string;
  end_date: string;
}

interface IAddGroupModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const EditGroupModal: React.FC<IAddGroupModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { editGroup, selectedGroup } = useGroups();

  const { maxDate } = useMemo(() => {
    const now = new Date();

    const max = new Date();
    max.setFullYear(now.getFullYear() + 10);

    const formattedMaxDate = format(max, 'yyyy-MM-dd');

    return { maxDate: formattedMaxDate };
  }, []);

  const { defaultStartDate, defaultEndDate } = useMemo(() => {
    const start_date = parseISO(selectedGroup.start_date);
    const end_date = parseISO(selectedGroup.end_date);

    return {
      defaultStartDate: format(start_date, 'yyyy-MM-dd'),
      defaultEndDate: format(end_date, 'yyyy-MM-dd'),
    };
  }, [selectedGroup.start_date, selectedGroup.end_date]);

  const handleSubmit = useCallback(
    async (data: IAddGroupFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da Turma é obrigatório'),
          code: Yup.string().required('Código da Turma é obrigatório'),
          start_date: Yup.string().required('Data de abertura é obrigatória'),
          end_date: Yup.string().required('Data de fechamento é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, code, start_date, end_date } = data;

        await editGroup({
          name,
          code,
          start_date,
          end_date,
        });

        addToast({
          type: 'success',
          title: 'Edição feita com sucesso!',
        });

        closeFunction();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possível editar a turma.',
          });
        }
      }

      setLoading(false);
    },
    [addToast, editGroup, closeFunction],
  );

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Editar Turma</header>

        <main>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor="name">Nome da Turma</label>

              <Input
                name="name"
                type="text"
                placeholder="Ex: Terceirão Bonja 2021"
                id="name"
                defaultValue={selectedGroup.name}
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="code">Código da Turma</label>
              <Input
                name="code"
                type="text"
                placeholder="Ex: TBONJA2021"
                id="code"
                defaultValue={selectedGroup.code}
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="start_date">Data de abertura</label>
              <Input
                id="start_date"
                name="start_date"
                type="date"
                max={maxDate}
                defaultValue={defaultStartDate}
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="end_date">Data de fechamento</label>
              <Input
                id="end_date"
                name="end_date"
                type="date"
                max={maxDate}
                defaultValue={defaultEndDate}
              />
            </InputGroup>

            <footer>
              <ModalButton
                type="button"
                action="cancel"
                onClick={closeFunction}
                disabled={isLoading}
              >
                Cancelar
              </ModalButton>

              <ModalButton type="submit" action="confirm" disabled={isLoading}>
                Salvar
              </ModalButton>
            </footer>
          </Form>
        </main>
      </Container>
    </Modal>
  );
};

export default EditGroupModal;

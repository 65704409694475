import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => (
  <Container>
    <p>Copyright &copy; Dominick Brasileiro 2021</p>
  </Container>
);

export default Footer;

/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function formatDate(date: Date | string): string | undefined {
  if (!date) {
    return undefined;
  }

  if (typeof date === 'string') {
    date = parseISO(date);
  }

  return format(date, "d 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  });
}

import React, { useCallback, useMemo } from 'react';
import SelectSearch from 'react-select-search';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import formatOrderStatus from '../../utils/formatOrderStatus';
import './react-select-search.css';

import { Container } from './styles';

type Status = 'processing' | 'waiting_payment' | 'paid' | 'refused' | 'errored';

interface StatusFilterProps {
  availableStatuses: Status[];
  onFilter: (statuses: string[]) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({
  availableStatuses,
  onFilter,
}) => {
  const handleChange = useCallback(
    statuses => {
      const txtStatuses = [...((statuses as unknown) as string[])];

      return onFilter(txtStatuses);
    },
    [onFilter],
  );

  const options = useMemo(() => {
    return availableStatuses.map(status => ({
      value: status,
      name: capitalizeFirstLetter(formatOrderStatus(status)),
    }));
  }, [availableStatuses]);

  return (
    <Container>
      <span>Filtrar por situação:</span>
      <SelectSearch
        printOptions="on-focus"
        multiple
        placeholder="Selecione um filtro"
        onChange={handleChange}
        options={options}
      />
    </Container>
  );
};

export default StatusFilter;

import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdLockOutline, MdMailOutline } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Footer from '../../components/Footer';

import { Container, SignInButton } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Formato inválido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard/groups');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possível realizar a autenticação',
          });
        }
      }

      setLoading(false);
    },
    [addToast, signIn, history],
  );

  return (
    <Container>
      <span>
        <MdLockOutline size={24} />
      </span>

      <h1>Entrar</h1>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          icon={MdMailOutline}
          name="email"
          type="text"
          placeholder="E-mail"
        />
        <Input
          icon={MdLockOutline}
          name="password"
          type="password"
          placeholder="Senha"
        />

        <SignInButton type="submit" disabled={isLoading}>
          Entrar
        </SignInButton>
      </Form>

      <Footer />
    </Container>
  );
};

export default SignIn;

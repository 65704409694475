import React from 'react';
import {
  MdGroup,
  MdLayers,
  // MdSettings,
  // MdGroupAdd,
  MdPowerSettingsNew,
} from 'react-icons/md';

import ReactLoading from 'react-loading';

import { useAuth } from '../../hooks/auth';

import { Container, Header, Menu, Content, LoadingContainer } from './styles';

import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';
import Footer from '../../components/Footer';

interface DashboardProps {
  title: string;
  isLoading?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({
  title,
  isLoading,
  children,
}) => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Header>
        <h1>{title}</h1>
      </Header>

      <Menu>
        <MenuGroup title="Básico">
          <MenuItem title="Turmas" icon={MdGroup} to="/dashboard/groups" />
          <MenuItem title="Pedidos" icon={MdLayers} to="/dashboard/orders" />
          <MenuItem
            title="Sair"
            icon={MdPowerSettingsNew}
            to="/"
            onClick={signOut}
          />
        </MenuGroup>

        {/* <MenuGroup title="Administração">
          <MenuItem
            title="Configurações"
            icon={MdSettings}
            to="/dashboard/groups"
          />
          <MenuItem
            title="Administradores"
            icon={MdGroupAdd}
            to="/dashboard/groups"
          />
        </MenuGroup> */}
      </Menu>

      {!isLoading ? (
        <Content>
          {children}

          <Footer />
        </Content>
      ) : (
        <LoadingContainer>
          <ReactLoading type="spin" color="#232323" />
        </LoadingContainer>
      )}
    </Container>
  );
};

export default Dashboard;

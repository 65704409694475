import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  span {
    margin-right: 8px;
    font-size: 16px;
  }
`;

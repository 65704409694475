import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Modal from '../../components/Modal';
import Input from '../../components/Input';

import { Container, InputGroup, ModalButton } from './styles';
import { useProducts } from '../../hooks/products';

interface IUpdateProductImageFormData {
  image_path: string;
}

interface IUpdateProductImageModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const UpdateProductImageModal: React.FC<IUpdateProductImageModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [imageFile, setImageFile] = useState<File>({} as File);
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { updateProductImage, selectedProduct } = useProducts();

  const handleSubmit = useCallback(
    async (data: IUpdateProductImageFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          image_path: Yup.string().required('Imagem é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await updateProductImage(selectedProduct.id, imageFile);

        addToast({
          type: 'success',
          title: 'Imagem atualizada com sucesso!',
        });

        closeFunction();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao atualizar a imagem o produto.',
          });
        }
      }

      setLoading(false);
    },
    [addToast, updateProductImage, selectedProduct, imageFile, closeFunction],
  );

  const handleImageFileChange = useCallback((fileList: FileList | null) => {
    if (fileList) {
      setImageFile(fileList[0]);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Atualizar Imagem</header>

        <main>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor="image_path">Imagem do Produto</label>
              <Input
                onChange={e => handleImageFileChange(e.target.files)}
                type="file"
                name="image_path"
                multiple={false}
                accept="image/*"
                id="image_path"
              />
            </InputGroup>

            <footer>
              <ModalButton
                type="button"
                action="cancel"
                onClick={closeFunction}
                disabled={isLoading}
              >
                Cancelar
              </ModalButton>

              <ModalButton type="submit" action="confirm" disabled={isLoading}>
                Salvar
              </ModalButton>
            </footer>
          </Form>
        </main>
      </Container>
    </Modal>
  );
};

export default UpdateProductImageModal;

import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Modal from '../../components/Modal';
import Input from '../../components/Input';
import NumberInput from '../../components/NumberInput';

import { Container, InputGroup, ModalButton } from './styles';
import { useProducts } from '../../hooks/products';
import { useGroups } from '../../hooks/groups';
import { DEFAULT_SIZES } from '../../constants/sizes';

interface ICreateProductFormData {
  name: string;
  price: string;
}

interface ICreateProductModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const CreateProductModal: React.FC<ICreateProductModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [imageFile, setImageFile] = useState<File>({} as File);
  const [isLoading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [useDefaultSizes, setUseDefaultSizes] = useState(true);

  const { addToast } = useToast();
  const { createProduct } = useProducts();
  const { selectedGroup } = useGroups();

  const handleSubmit = useCallback(
    async (data: ICreateProductFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do Produto é obrigatório'),
          price: Yup.string()
            .matches(/^R\$ \d+\.?\d+,?.{2}$/, 'Formato inválido')
            .required('Preço é obrigatório'),
          image_path: Yup.string().required('Imagem é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        await createProduct({
          group_id: selectedGroup.id,
          name,
          price,
          sizes: useDefaultSizes ? DEFAULT_SIZES : [],
          imageFile,
        });

        addToast({
          type: 'success',
          title: 'Produto adicionado com sucesso!',
        });

        closeFunction();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao adicionar o produto.',
          });
        }
      }

      setLoading(false);
    },
    [
      addToast,
      imageFile,
      price,
      createProduct,
      selectedGroup,
      useDefaultSizes,
      closeFunction,
    ],
  );

  const handleImageFileChange = useCallback((fileList: FileList | null) => {
    if (fileList) {
      setImageFile(fileList[0]);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Adicionar Produto</header>

        <main>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor="name">Nome do Produto</label>

              <Input
                name="name"
                type="text"
                placeholder="Ex: Camiseta Oficial Terceirão Bonja 2021"
                id="name"
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="price">Preço do Produto</label>
              <NumberInput
                name="price"
                type="text"
                placeholder="Ex: R$ 38,00"
                id="price"
                onValueChange={({ value }) => {
                  setPrice(Math.floor(Number(value) * 100));
                }}
                prefix="R$ "
                min={1}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="image_path">Imagem do Produto</label>
              <Input
                onChange={e => handleImageFileChange(e.target.files)}
                type="file"
                name="image_path"
                multiple={false}
                accept="image/*"
                id="image_path"
              />
            </InputGroup>

            <InputGroup>
              <label htmlFor="default_sizes">
                <input
                  type="checkbox"
                  id="default_sizes"
                  checked={useDefaultSizes}
                  onChange={e => setUseDefaultSizes(e.target.checked)}
                />
                Adicionar tamanhos padrão
              </label>
            </InputGroup>

            <footer>
              <ModalButton
                type="button"
                action="cancel"
                onClick={closeFunction}
                disabled={isLoading}
              >
                Cancelar
              </ModalButton>

              <ModalButton type="submit" action="confirm" disabled={isLoading}>
                Adicionar
              </ModalButton>
            </footer>
          </Form>
        </main>
      </Container>
    </Modal>
  );
};

export default CreateProductModal;

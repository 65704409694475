import React from 'react';
import { IconBaseProps } from 'react-icons';
import { LinkProps } from 'react-router-dom';

import { Container } from './styles';

interface MenuItemProps extends LinkProps {
  title: string;
  icon: React.ComponentType<IconBaseProps>;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, icon: Icon, ...rest }) => {
  return (
    <Container {...rest}>
      <Icon size={24} />

      <span>{title}</span>
    </Container>
  );
};

export default MenuItem;

import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { GroupsProvider } from './groups';
import { ProductsProvider } from './products';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <GroupsProvider>
          <ProductsProvider>{children}</ProductsProvider>
        </GroupsProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default AppProvider;

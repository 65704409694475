import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container, InputContainer, Error } from './styles';

interface InputProps extends NumberFormatProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const NumberInput: React.FC<InputProps> = ({ name, icon: Icon, ...rest }) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef?.value);
  }, [inputRef]);

  useEffect(() => {
    setIsFilled(!!inputRef?.defaultValue);
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);
  return (
    <Container>
      <InputContainer
        isErrored={!!error}
        isFocused={!!isFocused}
        isFilled={!!isFilled}
      >
        {Icon && <Icon />}

        <NumberFormat
          defaultValue={defaultValue}
          getInputRef={(ref: HTMLInputElement) => setInputRef(ref)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />
      </InputContainer>

      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default NumberInput;

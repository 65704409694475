import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import DashboardLayout from '../../layouts/Dashboard';
import formatBRL from '../../utils/formatBRL';
import formatDate from '../../utils/formatDate';
import formatOrderStatus from '../../utils/formatOrderStatus';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

import api from '../../services/api';

import { Container, Table, TableOrderStatus } from './styles';
import Card from '../../components/Card';
import OrdersChart from '../../components/OrdersChart';
import StatusFilter from '../../components/StatusFilter';

interface IOrder {
  id: string;
  code: number;
  external_id: string;
  user_name?: string;
  amount: number;
  status: 'processing' | 'waiting_payment' | 'paid' | 'refused';
  group?: {
    id: string;
    code: string;
  };
  created_at: string;
}

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      const response = await api.get('orders');

      setOrders(response.data);

      setLoading(false);
    }

    loadData();
  }, []);

  const handleFilter = useCallback((statuses: string[]) => {
    setStatusFilters(statuses);
  }, []);

  const filteredOrders = useMemo(() => {
    return statusFilters && statusFilters.length > 0
      ? orders.filter(order => statusFilters.includes(order.status))
      : orders;
  }, [orders, statusFilters]);

  const availableStatuses = useMemo(() => {
    const arr = orders.map(order => order.status);
    return [...new Set(arr)];
  }, [orders]);

  return (
    <DashboardLayout title="Pedidos" isLoading={isLoading}>
      <Container>
        <section>
          <Card title="Últimos 30 dias">
            <OrdersChart days={30} orders={orders} />
          </Card>
        </section>

        <section>
          <Card title="Pedidos">
            <StatusFilter
              availableStatuses={availableStatuses}
              onFilter={handleFilter}
            />

            <Table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>TID</th>
                  <th>Data</th>
                  <th>Nome</th>
                  <th>Turma</th>
                  <th>Situação</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map(order => (
                  <tr key={order.id}>
                    <td>{order.code}</td>
                    <td>{order.external_id}</td>
                    <td>{formatDate(order.created_at)}</td>
                    <td>{order.user_name}</td>
                    <td>
                      {order.group && (
                        <Link to={`/dashboard/groups/${order.group.id}`}>
                          {order.group.code}
                        </Link>
                      )}
                    </td>
                    <TableOrderStatus status={order.status}>
                      {capitalizeFirstLetter(formatOrderStatus(order.status))}
                    </TableOrderStatus>
                    <td>{formatBRL(order.amount / 100)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </section>
      </Container>
    </DashboardLayout>
  );
};

export default Orders;

import React from 'react';
import { Line } from 'react-chartjs-2';

import { format, isSameDay, parseISO, subDays } from 'date-fns';

import { Container } from './styles';

interface Order {
  id: string;
  created_at: string;
}

interface OrdersChartProps {
  days: number;
  orders: Order[];
}

const OrdersChart: React.FC<OrdersChartProps> = ({ days, orders }) => {
  const data = React.useMemo(() => {
    const today = new Date();

    const daysArray = Array.from(
      {
        length: days,
      },
      () => '',
    );

    const lastDaysArray = daysArray
      .map((str, index) => {
        const date = subDays(today, index);

        return date;
      })
      .reverse();

    const ordersQuantity = lastDaysArray.map(date => {
      const ordersInDay = orders.filter(order => {
        const orderCreatedAt = parseISO(order.created_at);

        return isSameDay(orderCreatedAt, date);
      });

      return ordersInDay.length;
    });

    const labels = lastDaysArray.map(date => {
      return format(date, 'dd/MM/yyyy');
    });

    return {
      labels,
      datasets: [
        {
          label: 'Quantidade de pedidos',
          fill: false,
          lineTension: 0.1,
          lineWidth: 20,
          backgroundColor: '#666666',
          borderColor: '#666666',
          borderCapStyle: 'butt',
          borderWidth: 2.5,
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#666666',
          pointBackgroundColor: '#666666',
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: '#666666',
          pointHoverBorderColor: '#666666',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: ordersQuantity,
        },
      ],
    };
  }, [days, orders]);

  return (
    <Container>
      <Line
        data={data}
        height={80}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  suggestedMax: 20,
                  precision: 0,
                },
              },
            ],
          },
        }}
      />
    </Container>
  );
};

export default OrdersChart;

import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Modal from '../../components/Modal';
import Input from '../../components/Input';

import { Container, InputGroup, ModalButton } from './styles';
import { useProducts } from '../../hooks/products';

interface IUpdateGroupMeasurementTableFormData {
  file_path: string;
}

interface IUpdateGroupMeasurementTableModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const UpdateProductMeasurementsModal: React.FC<IUpdateGroupMeasurementTableModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [file, setFile] = useState<File>({} as File);
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { updateProductMeasurements, selectedProduct } = useProducts();

  const handleSubmit = useCallback(
    async (data: IUpdateGroupMeasurementTableFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          file_path: Yup.string().required('Imagem é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await updateProductMeasurements(selectedProduct.id, file);

        addToast({
          type: 'success',
          title: 'Tabela de medidas atualizada com sucesso!',
        });

        closeFunction();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um erro ao atualizar a tabela de medidas',
          });
        }
      }

      setLoading(false);
    },
    [addToast, updateProductMeasurements, selectedProduct, file, closeFunction],
  );

  const handleFileChange = useCallback((fileList: FileList | null) => {
    if (fileList) {
      setFile(fileList[0]);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Tabela de Medidas</header>

        <main>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor="file_path">Nova Tabela</label>
              <Input
                onChange={e => handleFileChange(e.target.files)}
                type="file"
                name="file_path"
                multiple={false}
                accept="application/pdf"
                id="file_path"
              />
            </InputGroup>

            <footer>
              <ModalButton
                type="button"
                action="cancel"
                onClick={closeFunction}
                disabled={isLoading}
              >
                Cancelar
              </ModalButton>

              <ModalButton type="submit" action="confirm" disabled={isLoading}>
                Salvar
              </ModalButton>
            </footer>
          </Form>
        </main>
      </Container>
    </Modal>
  );
};

export default UpdateProductMeasurementsModal;

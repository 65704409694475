import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdPersonAdd, MdInfoOutline } from 'react-icons/md';

import DashboardLayout from '../../layouts/Dashboard';
import formatBRL from '../../utils/formatBRL';
import formatDate from '../../utils/formatDate';
import { useGroups } from '../../hooks/groups';

import {
  Container,
  OptionsContainer,
  OptionsContainerButton,
  Table,
} from './styles';
import Card from '../../components/Card';
import AddGroupModal from '../../modals/CreateGroupModal';
import formatGroupStatus from '../../utils/formatGroupStatus';

const Groups: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isAddGroupModalOpen, setAddGroupModalOpen] = useState(false);

  const { groups, loadGroups } = useGroups();

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      await loadGroups();

      setLoading(false);
    }

    loadData();
  }, [loadGroups]);

  return (
    <DashboardLayout title="Turmas" isLoading={isLoading}>
      <AddGroupModal
        isOpen={isAddGroupModalOpen}
        closeFunction={() => setAddGroupModalOpen(false)}
      />

      <Container>
        <Card title="Turmas">
          <OptionsContainer>
            <OptionsContainerButton
              type="button"
              onClick={() => setAddGroupModalOpen(true)}
            >
              <MdPersonAdd size={20} />
              Cadastrar Turma
            </OptionsContainerButton>
          </OptionsContainer>

          <p>
            <MdInfoOutline size={18} />
            Clique no nome de uma turma para acessar os detalhes
          </p>

          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Código da Turma</th>
                <th>Data de Abertura</th>
                <th>Data de Fechamento</th>
                <th>Status</th>
                <th>Total Arrecadado</th>
              </tr>
            </thead>
            <tbody>
              {groups.map(group => (
                <tr key={group.id}>
                  <td>
                    <Link to={`/dashboard/groups/${group.id}`}>
                      {group.name}
                    </Link>
                  </td>
                  <td>{group.code}</td>
                  <td>{formatDate(group.start_date)}</td>
                  <td>{formatDate(group.end_date)}</td>
                  <td
                    style={{
                      color: formatGroupStatus(group.available, group.closed)
                        .color,
                    }}
                  >
                    {formatGroupStatus(group.available, group.closed).text}
                  </td>
                  <td>{formatBRL((group.total_sold as number) / 100)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </DashboardLayout>
  );
};

export default Groups;

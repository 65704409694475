import React, { useCallback, useRef, useState } from 'react';
import {
  MdAdd,
  MdArrowDropDown,
  MdArrowDropUp,
  MdClose,
  MdDeleteForever,
} from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import {
  Container,
  ImageContainer,
  Title,
  DeleteButton,
  Sizes,
  Size,
  NoSizesText,
  AddSizeButton,
  Measurements,
  AddMeasurementsButton,
} from './styles';
import Input from '../../../components/Input';

import { useProducts, Product as IProduct } from '../../../hooks/products';
import { useToast } from '../../../hooks/toast';
import formatBRL from '../../../utils/formatBRL';
import getValidationErrors from '../../../utils/getValidationErrors';

interface ProductProps {
  product: IProduct;
  groupClosed: boolean;
  openUpdateImageModal: () => void;
  openDeleteModal: () => void;
  openUpdateMeasurementsModal: () => void;
}

const Product: React.FC<ProductProps> = ({
  product,
  groupClosed,
  openUpdateImageModal,
  openDeleteModal,
  openUpdateMeasurementsModal,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isOpen, setOpen] = useState(true);

  const {
    loadProductDetails,
    addSizeToProduct,
    deleteSizeFromProduct,
  } = useProducts();
  const { addToast } = useToast();

  const toggleOpen = useCallback(() => {
    setOpen(value => !value);
  }, []);

  const handleUpdateImage = useCallback(
    async (id: string) => {
      await loadProductDetails(id);

      openUpdateImageModal();
    },
    [loadProductDetails, openUpdateImageModal],
  );

  const handleUpdateMeasurements = useCallback(
    async (id: string) => {
      await loadProductDetails(id);

      openUpdateMeasurementsModal();
    },
    [loadProductDetails, openUpdateMeasurementsModal],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await loadProductDetails(id);

      openDeleteModal();
    },
    [loadProductDetails, openDeleteModal],
  );

  const handleAddSize = useCallback(
    async (data: { size: string }, { reset: resetForm }) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          size: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await addSizeToProduct(product.id, data.size.toUpperCase());

        addToast({
          title: 'Tamanho adicionado com sucesso!',
          type: 'success',
        });

        resetForm();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            title: 'Tamanho já existente.',
            type: 'error',
          });
        }
      }
    },
    [product.id, addSizeToProduct, addToast],
  );

  const handleDeleteSize = useCallback(
    async (size: string) => {
      // eslint-disable-next-line no-alert
      if (window.confirm('Deseja realmente deletar o tamanho?')) {
        try {
          await deleteSizeFromProduct(product.id, size.toUpperCase());

          addToast({
            title: 'Tamanho deletado com sucesso!',
            type: 'success',
          });
        } catch {
          addToast({
            title: 'Ocorreu um erro ao deletar o tamanho.',
            type: 'error',
          });
        }
      }
    },
    [product.id, deleteSizeFromProduct, addToast],
  );

  return (
    <Container key={product.id}>
      <ImageContainer onClick={() => handleUpdateImage(product.id)}>
        <img src={product.image_url} alt={product.name} />
      </ImageContainer>

      <main>
        <Title onClick={toggleOpen}>
          <strong>{product.name}</strong>
          {isOpen ? <MdArrowDropUp size={24} /> : <MdArrowDropDown size={24} />}
        </Title>

        <span>{formatBRL(product.price / 100)}</span>

        {product.sold_amount > 0 && (
          <span>
            <strong>Pedidos(a): </strong>
            {product.sold_amount}
          </span>
        )}

        <footer>
          {!groupClosed && (
            <DeleteButton
              type="button"
              onClick={() => handleDelete(product.id)}
              disabled={product.sold_amount > 0}
            >
              <MdDeleteForever size={16} />
              Deletar
            </DeleteButton>
          )}
        </footer>

        {isOpen && (
          <>
            <Sizes>
              <strong>Tamanhos disponíveis</strong>

              {product.available_sizes && product.available_sizes.length > 0 ? (
                <div>
                  {product.available_sizes.map((size, index) => (
                    <Size
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}_${size}`}
                      onClick={() => handleDeleteSize(size)}
                    >
                      {size}
                      <MdClose />
                    </Size>
                  ))}
                </div>
              ) : (
                <NoSizesText>Nenhum tamanho disponível.</NoSizesText>
              )}

              <Form onSubmit={handleAddSize} ref={formRef}>
                <Input name="size" showError={false} />
                <AddSizeButton type="submit">
                  <MdAdd size={22} />
                  Adicionar tamanho
                </AddSizeButton>
              </Form>
            </Sizes>

            <Measurements>
              {product.measurements ? (
                <>
                  <span>Tabela de medidas:</span>

                  <div>
                    <a href={product.measurements_url} target="__blank">
                      {product.measurements}
                    </a>
                    <button
                      type="button"
                      onClick={() => handleUpdateMeasurements(product.id)}
                    >
                      Atualizar
                    </button>
                  </div>
                </>
              ) : (
                <AddMeasurementsButton
                  type="button"
                  onClick={() => handleUpdateMeasurements(product.id)}
                >
                  <MdAdd size={22} />
                  Adicionar tabela de medidas
                </AddMeasurementsButton>
              )}
            </Measurements>
          </>
        )}
      </main>
    </Container>
  );
};

export default Product;

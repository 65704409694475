import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  MdEdit,
  MdDeleteForever,
  MdWarning,
  MdKeyboardBackspace,
  MdInfoOutline,
  MdAdd,
  MdGetApp,
  MdLayers,
  MdPictureAsPdf,
  MdShare,
} from 'react-icons/md';

import DashboardLayout from '../../layouts/Dashboard';
import formatBRL from '../../utils/formatBRL';
import formatDate from '../../utils/formatDate';
import formatOrderStatus from '../../utils/formatOrderStatus';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { useGroups } from '../../hooks/groups';
import { useProducts } from '../../hooks/products';
import { useToast } from '../../hooks/toast';

import {
  Container,
  BackButtonContainer,
  Details,
  GroupStatus,
  DetailsOptionsContainer,
  DetailsOptionButton,
  MeasurementTable,
  ProductOptionsContainer,
  ProductOptionsContainerButton,
  Table,
  TableOrderStatus,
  OrdersTableContainerHeader,
} from './styles';
import OrdersChart from '../../components/OrdersChart';
import Card from '../../components/Card';
import EditGroupModal from '../../modals/EditGroupModal';
import DeleteGroupModal from '../../modals/DeleteGroupModal';
import CreateProductModal from '../../modals/CreateProductModal';
import UpdateProductImageModal from '../../modals/UpdateProductImageModal';
import UpdateProductMeasurementsModal from '../../modals/UpdateProductMeasurementsModal';
import DeleteProductModal from '../../modals/DeleteProductModal';
import CloseGroupModal from '../../modals/CloseGroupModal';
import api from '../../services/api';
import Product from './Product';
import UpdateGroupMeasurementTableModal from '../../modals/UpdateGroupMeasurementTableModal';
import StatusFilter from '../../components/StatusFilter';

interface RouteParams {
  group_id: string;
}

interface Order {
  id: string;
  code: number;
  external_id: string;
  user_name?: string;
  amount: number;
  status: 'processing' | 'waiting_payment' | 'paid' | 'refused' | 'errored';
  created_at: string;
}

const GroupsDetails: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isEditGroupModalOpen, setEditGroupModalOpen] = useState(false);
  const [isDeleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
  const [isCreateProductModalOpen, setCreateProductModalOpen] = useState(false);
  const [
    isUpdateProductImageModalOpen,
    setUpdateProductImageModalOpen,
  ] = useState(false);
  const [
    isUpdateProductMeasurementsModalOpen,
    setUpdateProductMeasurementsModalOpen,
  ] = useState(false);
  const [isDeleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
  const [isCloseGroupModalOpen, setCloseGroupModalOpen] = useState(false);
  const [
    isUpdateMeasurementTableModalOpen,
    setUpdateMeasurementTableModalOpen,
  ] = useState(false);

  const history = useHistory();
  const { addToast } = useToast();
  const { params } = useRouteMatch<RouteParams>();

  const { loadGroupDetails, selectedGroup } = useGroups();
  const { products, loadProductsFromGroup } = useProducts();
  const [orders, setOrders] = useState<Order[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      await loadGroupDetails(params.group_id);
      await loadProductsFromGroup(params.group_id);

      const ordersResponse = await api.get(`groups/${params.group_id}/orders`);

      setOrders(ordersResponse.data);

      setLoading(false);
    }

    loadData();
  }, [loadGroupDetails, loadProductsFromGroup, params.group_id]);

  const groupStatus = useMemo(() => {
    if (selectedGroup.closed) {
      return 'Turma Finalizada';
    }

    if (selectedGroup.available) {
      return 'Turma Aberta';
    }

    return 'Turma Fechada';
  }, [selectedGroup]);

  const exportProductionOrderReport = useCallback(
    async (group_id: string) => {
      setLoading(true);

      try {
        const response = await api.post(
          `groups/${group_id}/reports/production-order`,
        );

        addToast({
          type: 'success',
          title: 'Ordem de produção gerada com sucesso!',
        });

        setTimeout(() => {
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: response.data.url,
          }).click();
        }, 1000);
      } catch (error) {
        addToast({
          type: 'error',
          title:
            'Ocorreu um erro ao gerar a ordem de produção. Tente novamente mais tarde.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const exportOrdersReport = useCallback(
    async (group_id: string) => {
      setLoading(true);

      try {
        const response = await api.post(`groups/${group_id}/reports/orders`);

        addToast({
          type: 'success',
          title: 'Pedidos exportados com sucesso!',
        });

        setTimeout(() => {
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: response.data.url,
          }).click();
        }, 1000);
      } catch (error) {
        addToast({
          type: 'error',
          title:
            'Ocorreu um erro ao exportar os pedidos. Tente novamente mais tarde.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const exportUsersReport = useCallback(
    async (group_id: string) => {
      setLoading(true);

      try {
        const response = await api.post(`groups/${group_id}/reports/users`);

        addToast({
          type: 'success',
          title: 'Pedidos exportados com sucesso!',
        });

        setTimeout(() => {
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: response.data.url,
          }).click();
        }, 1000);
      } catch (error) {
        addToast({
          type: 'error',
          title:
            'Ocorreu um erro ao exportar os pedidos. Tente novamente mais tarde.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleFilter = useCallback((statuses: string[]) => {
    setStatusFilters(statuses);
  }, []);

  const filteredOrders = useMemo(() => {
    return statusFilters && statusFilters.length > 0
      ? orders.filter(order => statusFilters.includes(order.status))
      : orders;
  }, [orders, statusFilters]);

  const availableStatuses = useMemo(() => {
    const arr = orders.map(order => order.status);
    return [...new Set(arr)];
  }, [orders]);

  return (
    <DashboardLayout title="Turmas" isLoading={isLoading}>
      <EditGroupModal
        isOpen={isEditGroupModalOpen}
        closeFunction={() => setEditGroupModalOpen(false)}
      />
      <DeleteGroupModal
        isOpen={isDeleteGroupModalOpen}
        closeFunction={() => setDeleteGroupModalOpen(false)}
      />
      <CreateProductModal
        isOpen={isCreateProductModalOpen}
        closeFunction={() => setCreateProductModalOpen(false)}
      />
      <UpdateProductImageModal
        isOpen={isUpdateProductImageModalOpen}
        closeFunction={() => setUpdateProductImageModalOpen(false)}
      />
      <UpdateProductMeasurementsModal
        isOpen={isUpdateProductMeasurementsModalOpen}
        closeFunction={() => setUpdateProductMeasurementsModalOpen(false)}
      />
      <DeleteProductModal
        isOpen={isDeleteProductModalOpen}
        closeFunction={() => setDeleteProductModalOpen(false)}
      />
      <CloseGroupModal
        isOpen={isCloseGroupModalOpen}
        closeFunction={() => setCloseGroupModalOpen(false)}
      />
      <UpdateGroupMeasurementTableModal
        isOpen={isUpdateMeasurementTableModalOpen}
        closeFunction={() => setUpdateMeasurementTableModalOpen(false)}
      />

      <Container>
        <BackButtonContainer onClick={() => history.push('/dashboard/groups')}>
          <button type="button">
            <MdKeyboardBackspace />
            Voltar
          </button>
        </BackButtonContainer>
        <section>
          <Card title="Detalhes da Turma">
            <Details>
              <ul>
                <div>
                  <li>
                    <strong>Turma:</strong>
                    {selectedGroup.name}
                  </li>

                  <li>
                    <strong>Código da Turma:</strong>
                    {selectedGroup.code}
                  </li>
                </div>

                <div>
                  <li>
                    <strong>Data de abertura:</strong>
                    {formatDate(selectedGroup.start_date)}
                  </li>

                  <li>
                    <strong>Data de fechamento:</strong>
                    {formatDate(selectedGroup.end_date)}
                  </li>
                </div>

                <div>
                  <li>
                    <strong>Número de usuários:</strong>
                    {selectedGroup.users_amount}
                  </li>

                  <li>
                    <strong>Número de pedidos:</strong>
                    {selectedGroup.orders_amount}
                  </li>
                </div>

                <div>
                  <li>
                    <strong>Total arrecadado:</strong>
                    {formatBRL(selectedGroup.total_sold / 100)}
                  </li>

                  <li>
                    <strong>Status:</strong>
                    <GroupStatus
                      available={selectedGroup.available}
                      closed={selectedGroup.closed}
                    >
                      {groupStatus}
                    </GroupStatus>
                  </li>
                </div>
              </ul>
            </Details>

            <DetailsOptionsContainer>
              {!selectedGroup.closed && (
                <>
                  <DetailsOptionButton
                    type="button"
                    action="edit"
                    onClick={() => setEditGroupModalOpen(true)}
                  >
                    <MdEdit size={20} />
                    Editar
                  </DetailsOptionButton>

                  <DetailsOptionButton
                    type="button"
                    action="delete"
                    onClick={() => setDeleteGroupModalOpen(true)}
                  >
                    <MdDeleteForever size={20} />
                    Deletar
                  </DetailsOptionButton>

                  <DetailsOptionButton
                    type="button"
                    action="close"
                    onClick={() => setCloseGroupModalOpen(true)}
                  >
                    <MdWarning size={20} />
                    Finalizar Turma
                  </DetailsOptionButton>
                </>
              )}

              <DetailsOptionButton
                type="button"
                action="export"
                onClick={() => exportProductionOrderReport(selectedGroup.id)}
              >
                <MdGetApp size={20} />
                Ordem de Produção
              </DetailsOptionButton>

              <DetailsOptionButton
                type="button"
                action="export"
                onClick={() => exportOrdersReport(selectedGroup.id)}
              >
                <MdLayers size={20} />
                Exportar Pedidos
              </DetailsOptionButton>
            </DetailsOptionsContainer>

            <MeasurementTable>
              {selectedGroup.measurement_table_url ? (
                <>
                  <span>Tabela de medidas:</span>

                  <div>
                    <a
                      href={selectedGroup.measurement_table_url}
                      target="__blank"
                    >
                      {selectedGroup.measurement_table}
                    </a>
                    <button
                      type="button"
                      onClick={() => setUpdateMeasurementTableModalOpen(true)}
                    >
                      Atualizar
                    </button>
                  </div>
                </>
              ) : (
                <DetailsOptionButton
                  type="button"
                  action="export"
                  onClick={() => setUpdateMeasurementTableModalOpen(true)}
                >
                  <MdPictureAsPdf size={20} />
                  Adicionar tabela de medidas
                </DetailsOptionButton>
              )}
            </MeasurementTable>
          </Card>
        </section>

        <section>
          <Card title="Produtos">
            <p>
              <MdInfoOutline size={18} />
              Clique no nome de um produto para acessar os detalhes
            </p>

            {products.map(product => (
              <Product
                key={product.id}
                product={product}
                groupClosed={selectedGroup.closed}
                openDeleteModal={() => setDeleteProductModalOpen(true)}
                openUpdateImageModal={
                  () => setUpdateProductImageModalOpen(true)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                openUpdateMeasurementsModal={
                  () => setUpdateProductMeasurementsModalOpen(true)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            ))}

            {!selectedGroup.closed && (
              <ProductOptionsContainer>
                <ProductOptionsContainerButton
                  type="button"
                  onClick={() => setCreateProductModalOpen(true)}
                >
                  <MdAdd size={22} />
                  Adicionar Produto
                </ProductOptionsContainerButton>
              </ProductOptionsContainer>
            )}
          </Card>
        </section>

        {!selectedGroup.closed && (
          <section>
            <Card title="Últimos 7 dias">
              <OrdersChart days={7} orders={orders} />
            </Card>
          </section>
        )}

        <section>
          <Card title="Pedidos">
            <OrdersTableContainerHeader>
              <StatusFilter
                availableStatuses={availableStatuses}
                onFilter={handleFilter}
              />

              <DetailsOptionButton
                type="button"
                action="export"
                onClick={() => exportUsersReport(selectedGroup.id)}
              >
                <MdShare size={20} />
                Exportar
              </DetailsOptionButton>
            </OrdersTableContainerHeader>

            <Table>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>TID</th>
                  <th>Data</th>
                  <th>Nome</th>
                  <th>Situação</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map(order => (
                  <tr key={order.id}>
                    <td>{order.code}</td>
                    <td>{order.external_id}</td>
                    <td>{formatDate(order.created_at)}</td>
                    <td>{order.user_name}</td>
                    <TableOrderStatus status={order.status}>
                      {capitalizeFirstLetter(formatOrderStatus(order.status))}
                    </TableOrderStatus>
                    <td>{formatBRL(order.amount / 100)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </section>
      </Container>
    </DashboardLayout>
  );
};

export default GroupsDetails;

import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  section {
    width: 100%;

    & + section {
      margin-top: 24px;
    }
  }

  p {
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.6);

    svg {
      margin-right: 4px;
      transform: translateY(-1px);
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  thead {
    border-bottom: 1px solid #ddd;
  }

  th {
    text-align: left;
    font-weight: 500;
    padding: 8px 16px 8px 24px;

    &:last-child {
      text-align: right;
    }
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 16px 16px 16px 24px;
    vertical-align: middle;
    line-height: 14px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

interface TableOrderStatusProps {
  status: 'processing' | 'waiting_payment' | 'paid' | 'refused' | 'errored';
}

export const TableOrderStatus = styled.td<TableOrderStatusProps>`
  ${props => {
    switch (props.status) {
      case 'processing':
        return css`
          color: ${shade(0.2, '#d35400')};
        `;

      case 'waiting_payment':
        return css`
          color: #d35400;
        `;

      case 'paid':
        return css`
          color: #2d6930;
        `;

      case 'refused':
      case 'errored':
        return css`
          color: #d80700;
        `;

      default:
        return css`
          color: ${shade(0.2, '#d35400')};
        `;
    }
  }};
`;

import React from 'react';
import ReactModal from 'react-modal';

interface IModalProps {
  isOpen: boolean;
  closeFunction: () => void;
  width?: number;
}

const Modal: React.FC<IModalProps> = ({
  isOpen,
  closeFunction,
  width = 480,
  children,
}) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      onRequestClose={closeFunction}
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: '#f7f7f7',
          color: '#000000',
          boxShadow:
            '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0,0,0,.12)',
          borderRadius: '4px',
          width: `${width}px`,
          border: 'none',
          padding: '24px',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 15,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;

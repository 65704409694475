import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => clearTimeout(timer);
  }, [message.id, removeToast]);

  return (
    <Container type={message.type} style={style}>
      <span>{message.title}</span>

      <button type="button">
        <MdClose size={18} onClick={() => removeToast(message.id)} />
      </button>
    </Container>
  );
};

export default Toast;

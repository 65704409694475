import React from 'react';

import { Container, Title, Content, Link } from './styles';

interface CardProps {
  title: string;
  navigateTo?: string;
  navigateText?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  navigateTo,
  navigateText,
  children,
}) => {
  return (
    <Container>
      <Title>{title}</Title>

      <Content>{children}</Content>

      {navigateTo && navigateText && (
        <Link to={navigateTo}>{navigateText}</Link>
      )}
    </Container>
  );
};

export default Card;

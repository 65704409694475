import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Groups from '../pages/Groups';
import GroupDetails from '../pages/GroupDetails';
import Orders from '../pages/Orders';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route isPrivate path="/dashboard/groups" exact component={Groups} />
      <Route
        isPrivate
        path="/dashboard/groups/:group_id"
        exact
        component={GroupDetails}
      />
      <Route isPrivate path="/dashboard/orders" exact component={Orders} />
    </Switch>
  );
};

export default Routes;

import styled from 'styled-components';

export const Container = styled.div`
  & + div {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  > span {
    display: block;
    text-align: center;
    padding: 16px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }
`;

import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

interface ICreateProductDTO {
  group_id: string;
  name: string;
  price: number;
  sizes: string[];
  imageFile: File;
}

interface ProductsContextData {
  products: Product[];
  selectedProduct: Product;
  loadProductsFromGroup(group_id: string): Promise<void>;
  loadProductDetails(id: string): Promise<void>;
  createProduct(data: ICreateProductDTO): Promise<void>;
  updateProductImage(id: string, file: File): Promise<void>;
  updateProductMeasurements(id: string, file: File): Promise<void>;
  deleteProduct(id: string): Promise<void>;
  addSizeToProduct(product_id: string, size: string): Promise<void>;
  deleteSizeFromProduct(product_id: string, size: string): Promise<void>;
}

export interface Product {
  id: string;
  group_id: string;
  name: string;
  price: number;
  image_url: string;
  sold_amount: number;
  available_sizes: string[];
  measurements: string;
  measurements_url: string;
}

const ProductsContext = createContext<ProductsContextData>(
  {} as ProductsContextData,
);

const ProductsProvider: React.FC = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>(
    {} as Product,
  );

  const loadProductsFromGroup = useCallback(async (group_id: string) => {
    const response = await api.get<Product[]>(`/groups/${group_id}/products`);

    setProducts(response.data);
  }, []);

  const loadProductDetails = useCallback(async (id: string) => {
    const response = await api.get<Product>(`/products/${id}`);

    const productDetails = response.data;

    setSelectedProduct(productDetails);
  }, []);

  const createProduct = useCallback(
    async ({ group_id, name, price, sizes, imageFile }: ICreateProductDTO) => {
      const createProductResponse = await api.post<Product>('products', {
        group_id,
        name,
        price,
        sizes,
      });

      const createdProduct = createProductResponse.data;

      const formData = new FormData();
      formData.append('image', imageFile);

      const response = await api.patch(
        `products/${createdProduct.id}`,
        formData,
      );

      const product = response.data;

      setProducts(oldProducts => [...oldProducts, product]);
    },
    [],
  );

  const updateProductImage = useCallback(async (id: string, file: File) => {
    const formData = new FormData();
    formData.append('image', file);

    const response = await api.patch(`products/${id}`, formData);

    const product = response.data;

    setProducts(oldProducts => {
      const productsArray = [...oldProducts];

      const productIndex = productsArray.findIndex(
        oldProduct => oldProduct.id === product.id,
      );

      productsArray.splice(productIndex, 1);

      productsArray[productIndex] = product;

      return productsArray;
    });
  }, []);

  const updateProductMeasurements = useCallback(
    async (id: string, file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.patch(`products/${id}/measurements`, formData);

      const product = response.data;

      setProducts(oldProducts => {
        const productsArray = [...oldProducts];

        const productIndex = productsArray.findIndex(
          oldProduct => oldProduct.id === product.id,
        );

        productsArray[productIndex] = product;

        return productsArray;
      });
    },
    [],
  );

  const deleteProduct = useCallback(async (id: string) => {
    await api.delete(`products/${id}`);

    setProducts(oldProducts => {
      const productsArray = [...oldProducts];

      const productIndex = productsArray.findIndex(
        oldProduct => oldProduct.id === id,
      );

      productsArray.splice(productIndex, 1);

      return productsArray;
    });
  }, []);

  const addSizeToProduct = useCallback(
    async (product_id: string, size: string) => {
      await api.post(`products/${product_id}/sizes`, {
        size,
      });

      const product = products.find(_product => _product.id === product_id);

      if (product) {
        const updatedProduct = { ...product };

        updatedProduct.available_sizes.push(size);

        setProducts(oldProducts => {
          const productsArray = [...oldProducts];

          const productIndex = productsArray.findIndex(
            oldProduct => oldProduct.id === product.id,
          );

          productsArray[productIndex] = updatedProduct;

          return productsArray;
        });
      }
    },
    [products],
  );

  const deleteSizeFromProduct = useCallback(
    async (product_id: string, size: string) => {
      await api.delete(`products/${product_id}/sizes/${size}`);

      const product = products.find(_product => _product.id === product_id);

      if (product) {
        const updatedProduct = { ...product };

        const sizeIndex = updatedProduct.available_sizes.findIndex(
          _size => _size === size,
        );

        updatedProduct.available_sizes.splice(sizeIndex, 1);

        setProducts(oldProducts => {
          const productsArray = [...oldProducts];

          const productIndex = productsArray.findIndex(
            oldProduct => oldProduct.id === product.id,
          );

          productsArray[productIndex] = updatedProduct;

          return productsArray;
        });
      }
    },
    [products],
  );

  return (
    <ProductsContext.Provider
      value={{
        products,
        selectedProduct,
        loadProductsFromGroup,
        loadProductDetails,
        createProduct,
        updateProductImage,
        updateProductMeasurements,
        deleteProduct,
        addSizeToProduct,
        deleteSizeFromProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

function useProducts(): ProductsContextData {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('useProducts must be used within a ProductsProvider');
  }

  return context;
}

export { ProductsProvider, useProducts };

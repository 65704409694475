import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Button from '../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  section {
    width: 100%;

    & + section {
      margin-top: 24px;
    }
  }

  p {
    display: flex;
    align-items: center;
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.6);

    svg {
      margin-right: 4px;
      transform: translateY(-1px);
    }
  }
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);

    background-color: transparent;
    outline: 0;
    border: 0;

    svg {
      margin-right: 8px;
    }
  }
`;

export const Details = styled.div`
  margin-top: 24px;

  ul {
    display: flex;
    list-style: none;

    div + div {
      margin-left: 100px;
    }

    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 4px;
    }

    li + li {
      margin-top: 16px;
    }
  }
`;

interface GroupStatusProps {
  available: boolean;
  closed: boolean;
}

export const GroupStatus = styled.span<GroupStatusProps>`
  ${props =>
    props.available
      ? css`
          color: green;
        `
      : css`
          color: red;
        `}

  ${props =>
    props.closed &&
    css`
      color: navy;
    `}
`;

export const DetailsOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  flex-wrap: wrap;
`;

interface CrudButtonProps {
  action: 'edit' | 'delete' | 'close' | 'export';
}

export const DetailsOptionButton = styled(Button)<CrudButtonProps>`
  & + button {
    margin-left: 8px;
  }

  ${props =>
    props.action === 'edit' &&
    css`
      background-color: #f1c40f;

      &:hover {
        background-color: ${shade(0.2, '#f1c40f')};
      }
    `}

  ${props =>
    props.action === 'delete' &&
    css`
      background-color: #e74c3c;

      &:hover {
        background-color: ${shade(0.2, '#e74c3c')};
      }
    `}

    ${props =>
    props.action === 'close' &&
    css`
      background-color: #e74c3c;

      &:hover {
        background-color: ${shade(0.2, '#e74c3c')};
      }
    `}

    ${props =>
    props.action === 'export' &&
    css`
      background-color: #4caf50;

      &:hover {
        background-color: ${shade(0.2, '#4caf50')};
      }
    `}

  svg {
    margin-right: 8px;
  }
`;

export const MeasurementTable = styled.div`
  display: flex;
  margin-top: 16px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    a {
      font-style: italic;
      color: inherit;
      margin-left: 4px;
      text-decoration: none;
    }

    button {
      margin-top: 4px;
      background: none;
      border: none;
      outline: none;
      text-decoration: underline;
      font-style: italic;
    }
  }
`;

export const ProductOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;

  border-top: 1px solid #ddd;
`;

export const ProductOptionsContainerButton = styled(Button)`
  background-color: #4caf50;

  &:hover {
    background-color: ${shade(0.2, '#4caf50')};
  }

  svg {
    margin-right: 8px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  thead {
    border-bottom: 1px solid #ddd;
  }

  th {
    text-align: left;
    font-weight: 500;
    padding: 8px 16px 8px 24px;

    &:last-child {
      text-align: right;
    }
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 16px 16px 16px 24px;
    vertical-align: middle;
    line-height: 14px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

interface TableOrderStatusProps {
  status: 'processing' | 'waiting_payment' | 'paid' | 'refused' | 'errored';
}

export const TableOrderStatus = styled.td<TableOrderStatusProps>`
  ${props => {
    switch (props.status) {
      case 'processing':
        return css`
          color: ${shade(0.2, '#d35400')};
        `;

      case 'waiting_payment':
        return css`
          color: #d35400;
        `;

      case 'paid':
        return css`
          color: #2d6930;
        `;

      case 'refused':
      case 'errored':
        return css`
          color: #d80700;
        `;

      default:
        return css`
          color: ${shade(0.2, '#d35400')};
        `;
    }
  }};
`;

export const OrdersTableContainerHeader = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    flex: 1;
  }
`;

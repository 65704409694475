import styled from 'styled-components';
import { darken } from 'polished';

import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;

  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;

  &:hover {
    background-color: ${darken(0.1, '#f7f7f7')};
  }

  > svg {
    fill: rgba(0, 0, 0, 0.7);
  }

  > span {
    margin-left: 24px;
    color: inherit;
  }
`;

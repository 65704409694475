import styled, { keyframes } from 'styled-components';

const fadeInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 64px 1fr;
  grid-template-columns: 240px 1fr;
  grid-template-areas:
    'header header'
    'menu content';

  background-color: #f7f7f7;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  width: 100%;

  z-index: 10;

  padding: 0 16px;
  background-color: #232323;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  h1 {
    color: #fff;
    font-size: 20px;
    margin-left: 20px;
    user-select: none;
  }
`;

export const Menu = styled.aside`
  grid-area: menu;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background: #f7f7f7;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Content = styled.main`
  grid-area: content;
  padding: 24px 24px 32px;
  overflow: auto;

  > * {
    animation: ${fadeInFromRight} 0.68s;
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
`;

export const LoadingContainer = styled.div`
  grid-area: content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

import styled from 'styled-components';
import { Link as LinkElement } from 'react-router-dom';

export const Container = styled.div`
  padding: 16px;
  background-color: #fafafa;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const Title = styled.div`
  display: block;
  color: #444;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const Link = styled(LinkElement)`
  display: block;
  text-decoration: none;
  font-size: 14px;
  margin-top: 14px;
  color: #444;

  &:hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div``;

import styled, { css } from 'styled-components';
import { darken } from 'polished';

interface ModalButtonProps {
  action: 'cancel' | 'confirm';
}

export const Container = styled.div`
  header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 24px;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }
`;

export const InputGroup = styled.div`
  color: rgba(0, 0, 0, 0.8);

  & + div {
    margin-top: 16px;
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0 8px 4px;
  }

  input[type='checkbox'] {
    margin-right: 6px;
    font-size: 32px;
    width: 16px;
    height: 16px;
  }
`;

export const ModalButton = styled.button<ModalButtonProps>`
  background-color: transparent;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 4px;

  transition: color 0.1s;

  & + button {
    margin-left: 24px;
  }

  ${props =>
    props.action === 'cancel' &&
    css`
      color: #000000cc;

      &:hover {
        color: #000;
      }
    `}

  ${props =>
    props.action === 'confirm' &&
    css`
      font-weight: 500;
      color: #27ae60;

      &:hover {
        color: ${darken(0.1, '#27ae60')};
      }
    `}

    ${props =>
    props.disabled &&
    css`
      color: #ccc;

      &:hover {
        color: #ccc;
      }
    `}
`;

import React, { createContext, useCallback, useContext, useState } from 'react';
import { parseISO, isBefore, startOfDay, endOfDay } from 'date-fns';
import api from '../services/api';

interface ICreateGroupDTO {
  name: string;
  code: string;
  start_date: string;
  end_date: string;
}

interface IEditGroupDTO {
  name: string;
  code: string;
  start_date: string;
  end_date: string;
}

interface GroupsContextData {
  groups: Group[];
  selectedGroup: GroupDetails;
  loadGroups(): Promise<void>;
  loadGroupDetails(id: string): Promise<void>;
  createGroup(data: ICreateGroupDTO): Promise<void>;
  editGroup(data: IEditGroupDTO): Promise<void>;
  deleteGroup(id: string): Promise<void>;
  closeGroup(id: string): Promise<void>;
  updateGroupMeasurementTable(id: string, file: File): Promise<void>;
}

interface Group {
  id: string;
  name: string;
  code: string;
  start_date: string;
  end_date: string;
  available: boolean;
  closed: boolean;
  total_sold: number;
}

interface GroupDetails extends Group {
  users_amount: number;
  orders_amount: number;
  measurement_table?: string;
  measurement_table_url?: string;
}

const GroupsContext = createContext<GroupsContextData>({} as GroupsContextData);

const GroupsProvider: React.FC = ({ children }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupDetails>(
    {} as GroupDetails,
  );

  const loadGroups = useCallback(async () => {
    const response = await api.get('/groups');

    setGroups(response.data);
  }, []);

  const loadGroupDetails = useCallback(async (id: string) => {
    const response = await api.get(`/groups/${id}`);

    const groupDetails = response.data;

    setSelectedGroup(groupDetails);
  }, []);

  const createGroup = useCallback(
    async ({ name, code, start_date, end_date }: ICreateGroupDTO) => {
      const response = await api.post('groups', {
        name,
        code,
        start_date,
        end_date,
      });

      const group = response.data;

      group.total_sold = 0;

      setGroups(oldGroups => [...oldGroups, group]);
    },
    [],
  );

  const editGroup = useCallback(
    async ({ name, code, start_date, end_date }: IEditGroupDTO) => {
      await api.put(`groups/${selectedGroup.id}`, {
        name,
        code,
        start_date,
        end_date,
      });

      const newGroup = { ...selectedGroup };

      const startDate = startOfDay(parseISO(start_date));
      const endDate = endOfDay(parseISO(end_date));

      const available =
        isBefore(startDate, Date.now()) && isBefore(Date.now(), endDate);

      newGroup.name = name;
      newGroup.code = code;
      newGroup.available = available;
      newGroup.start_date = start_date;
      newGroup.end_date = end_date;

      setSelectedGroup(newGroup);

      const groupsArray = [...groups];

      const updatedGroupIndex = groupsArray.findIndex(
        group => group.id === selectedGroup.id,
      );

      groupsArray.splice(updatedGroupIndex, 1);

      groupsArray[updatedGroupIndex] = newGroup;

      setGroups(groupsArray);
    },
    [groups, selectedGroup],
  );

  const deleteGroup = useCallback(async (id: string) => {
    await api.delete(`groups/${id}`);
  }, []);

  const closeGroup = useCallback(async (id: string) => {
    await api.post(`groups/${id}/close`);

    setSelectedGroup(oldSelectedGroup => {
      return {
        ...oldSelectedGroup,
        closed: true,
      };
    });
  }, []);

  const updateGroupMeasurementTable = useCallback(
    async (id: string, file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.patch(`groups/${id}`, formData);

      const group = response.data;

      setSelectedGroup(group);
    },
    [],
  );

  return (
    <GroupsContext.Provider
      value={{
        groups,
        selectedGroup,
        loadGroups,
        loadGroupDetails,
        createGroup,
        editGroup,
        deleteGroup,
        closeGroup,
        updateGroupMeasurementTable,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

function useGroups(): GroupsContextData {
  const context = useContext(GroupsContext);

  if (!context) {
    throw new Error('useGroups must be used within a GroupsProvider');
  }

  return context;
}

export { GroupsProvider, useGroups };

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '../../components/Modal';
import { useGroups } from '../../hooks/groups';
import { useToast } from '../../hooks/toast';

import { Container, ModalButton } from './styles';

interface IDeleteGroupModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const DeleteGroupModal: React.FC<IDeleteGroupModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const history = useHistory();

  const { addToast } = useToast();
  const { deleteGroup, selectedGroup } = useGroups();

  const handleDeleteGroup = useCallback(async () => {
    await deleteGroup(selectedGroup.id);

    addToast({
      type: 'success',
      title: 'Turma deletada com sucesso!',
    });

    closeFunction();

    history.push('/dashboard/groups');
  }, [deleteGroup, addToast, selectedGroup.id, closeFunction, history]);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Deletar Turma</header>

        <main>
          <p>
            Você está prestes a deletar a turma:{' '}
            <strong>{selectedGroup.name}</strong>
          </p>

          <p>
            Após deletar, todos os dados relacionados a essa turma serão
            apagados.
          </p>

          <p>Observação: Essa operação não pode ser desfeita.</p>
        </main>

        <footer>
          <ModalButton type="button" action="cancel" onClick={closeFunction}>
            Cancelar
          </ModalButton>

          <ModalButton
            type="button"
            action="delete"
            onClick={handleDeleteGroup}
          >
            Deletar
          </ModalButton>
        </footer>
      </Container>
    </Modal>
  );
};

export default DeleteGroupModal;

import styled, { css } from 'styled-components';
import { darken } from 'polished';

interface ModalButtonProps {
  action: 'cancel' | 'delete';
}

export const Container = styled.div`
  header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 24px;
  }

  main {
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;

    strong {
      font-weight: 500;
      color: #000;
    }

    p + p {
      margin-top: 12px;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }
`;

export const ModalButton = styled.button<ModalButtonProps>`
  background-color: transparent;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 4px;

  transition: color 0.1s;

  & + button {
    margin-left: 24px;
  }

  ${props =>
    props.action === 'cancel' &&
    css`
      color: #000000cc;

      &:hover {
        color: #000;
      }
    `}

  ${props =>
    props.action === 'delete' &&
    css`
      font-weight: 500;
      color: #ec3030;

      &:hover {
        color: ${darken(0.1, '#ec3030')};
      }
    `}

    ${props =>
    props.disabled &&
    css`
      color: #ccc;

      &:hover {
        color: #ccc;
      }
    `}
`;

import React, { useCallback, useState } from 'react';

import Modal from '../../components/Modal';
import { useProducts } from '../../hooks/products';
import { useToast } from '../../hooks/toast';

import { Container, ModalButton } from './styles';

interface IDeleteProductModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const DeleteProductModal: React.FC<IDeleteProductModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { deleteProduct, selectedProduct } = useProducts();

  const handleDeleteProduct = useCallback(async () => {
    setLoading(true);

    try {
      await deleteProduct(selectedProduct.id);

      addToast({
        type: 'success',
        title: 'Produto deletado com sucesso!',
      });

      closeFunction();
    } catch {
      addToast({
        type: 'error',
        title: 'Não foi possível deletar o produto.',
      });
    }

    setLoading(false);
  }, [deleteProduct, addToast, selectedProduct.id, closeFunction]);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Deletar Produto</header>

        <main>
          <p>
            Você está prestes a deletar o produto:{' '}
            <strong>{selectedProduct.name}</strong>
          </p>

          <p>Observação: Essa operação não pode ser desfeita.</p>
        </main>

        <footer>
          <ModalButton
            type="button"
            action="cancel"
            onClick={closeFunction}
            disabled={isLoading}
          >
            Cancelar
          </ModalButton>

          <ModalButton
            type="button"
            action="delete"
            onClick={handleDeleteProduct}
            disabled={isLoading}
          >
            Deletar
          </ModalButton>
        </footer>
      </Container>
    </Modal>
  );
};

export default DeleteProductModal;

import React, { useCallback } from 'react';

import Modal from '../../components/Modal';
import { useGroups } from '../../hooks/groups';
import { useToast } from '../../hooks/toast';

import { Container, ModalButton } from './styles';

interface ICloseGroupModalProps {
  isOpen: boolean;
  closeFunction: () => void;
}

const CloseGroupModal: React.FC<ICloseGroupModalProps> = ({
  isOpen,
  closeFunction,
}) => {
  const { addToast } = useToast();
  const { closeGroup, selectedGroup } = useGroups();

  const handleCloseGroup = useCallback(async () => {
    try {
      await closeGroup(selectedGroup.id);

      addToast({
        type: 'success',
        title: 'Turma finalizada com sucesso!',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao finalizar a turma.',
      });
    }

    closeFunction();
  }, [closeGroup, addToast, selectedGroup.id, closeFunction]);

  return (
    <Modal isOpen={isOpen} closeFunction={closeFunction}>
      <Container>
        <header>Finalizar Turma</header>

        <main>
          <p>
            Você está prestes a finalizar a turma:{' '}
            <strong>{selectedGroup.name}</strong>
          </p>

          <p>
            Após finalizar, os dados dessa turma ficarão disponíveis apenas para
            consulta. Além disso, todos os usuários dela serão removidos, para
            futuramente entrarem em outra turma.
          </p>

          <p>Observação: Essa operação não pode ser desfeita.</p>
        </main>

        <footer>
          <ModalButton type="button" action="cancel" onClick={closeFunction}>
            Cancelar
          </ModalButton>

          <ModalButton type="button" action="delete" onClick={handleCloseGroup}>
            Finalizar
          </ModalButton>
        </footer>
      </Container>
    </Modal>
  );
};

export default CloseGroupModal;

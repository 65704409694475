import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Button from '../../components/Button';

interface OptionButtonProps {
  action: 'edit' | 'delete';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  section {
    width: 100%;

    & + section {
      margin-top: 24px;
    }
  }

  p {
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.6);

    svg {
      margin-right: 4px;
      transform: translateY(-1px);
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
`;

export const OptionsContainerButton = styled(Button)`
  background-color: #4caf50;

  &:hover {
    background-color: ${shade(0.2, '#4caf50')};
  }

  svg {
    margin-right: 8px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  thead {
    border-bottom: 1px solid #ddd;
  }

  th {
    text-align: left;
    font-weight: 500;
    padding: 8px 16px 8px 24px;

    &:last-child {
      text-align: right;
    }
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 16px 16px 16px 24px;
    vertical-align: middle;
    line-height: 14px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const OptionButton = styled.button<OptionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;

  transition: background-color 0.1s;

  & + button {
    margin-left: 8px;
  }

  svg {
    fill: #eee;
  }

  ${props =>
    props.action === 'edit' &&
    css`
      background-color: #f1c40f;

      &:hover {
        background-color: ${shade(0.05, '#f1c40f')};
      }
    `}

  ${props =>
    props.action === 'delete' &&
    css`
      background-color: #e74c3c;

      &:hover {
        background-color: ${shade(0.05, '#e74c3c')};
      }
    `}
`;

export default function formatOrderStatus(
  status: 'processing' | 'waiting_payment' | 'paid' | 'refused' | 'errored',
): string {
  switch (status) {
    case 'processing':
      return 'processando';

    case 'waiting_payment':
      return 'aguardando pagamento';

    case 'paid':
      return 'pago';

    case 'refused':
      return 'recusado';

    case 'errored':
      return 'erro';

    default:
      return status;
  }
}

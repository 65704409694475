export const DEFAULT_SIZES: string[] = [
  'PP BABY LOOK',
  'P BABY LOOK',
  'M BABY LOOK',
  'G BABY LOOK',
  'GG BABY LOOK',
  'XG BABY LOOK',
  'ESP BABY LOOK',
  'PP',
  'P',
  'M',
  'G',
  'GG',
  'XG',
  'ESP',
];

import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Button from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #ddd;
  padding: 16px 0;

  main {
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    > span + span {
      margin-top: 10px;
    }

    strong {
      font-weight: 500;

      > a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  padding: 6px;
  cursor: pointer;

  &::after {
    content: 'Alterar';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  img {
    width: 92px;
    height: 92px;
    border-radius: 8px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px 8px;

  color: #fff;
  outline: 0;
  border: none;
  border-radius: 2px;

  transition: background-color 0.1s;

  background-color: #e74c3c;

  &:hover {
    background-color: ${shade(0.05, '#e74c3c')};
  }

  &:disabled {
    background-color: #dcdce5;
    color: #9c98a6;
    cursor: not-allowed;

    &:hover {
      background-color: #dcdce5;
    }
  }

  & + button {
    margin-left: 6px;
  }

  > svg {
    margin-right: 6px;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;

  border-top: 1px solid #ddd;
`;

export const Sizes = styled.div`
  > strong {
    display: block;

    margin: 16px 0 4px;
  }
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;

    max-width: 300px;

    > svg {
      margin-left: 8px;
    }
  }

  > form {
    display: flex;
    align-items: stretch;

    div > div {
      padding: 8px 12px;
    }
  }
`;

export const Size = styled.span`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 12px;
  font-size: 14px;
  background-color: #d6d6e0;

  margin: 6px;
  position: relative;
  left: -6px;

  cursor: pointer;
  > svg {
    margin-left: 8px;
    opacity: 0;
  }

  &:hover > svg {
    opacity: 1;
  }
`;

export const NoSizesText = styled.div`
  margin: 24px 0 !important;
  opacity: 0.85;
`;

const defaultButton = css`
  background-color: #4caf50;
  padding: 8px 14px;
  box-shadow: none;

  &:hover {
    background-color: ${shade(0.2, '#4caf50')};
  }

  svg {
    margin-right: 8px;
  }
`;

export const AddSizeButton = styled(Button)`
  ${defaultButton}
  margin-left: 8px;
`;

export const AddMeasurementsButton = styled(Button)`
  ${defaultButton}
`;

export const Measurements = styled.div`
  display: flex;
  margin-top: 16px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    a {
      font-style: italic;
      color: inherit;
      margin-left: 4px;
      text-decoration: none;
    }

    button {
      margin-top: 4px;
      background: none;
      border: none;
      outline: none;
      text-decoration: underline;
      font-style: italic;
    }
  }
`;

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3333',
});

const token = localStorage.getItem('@JanaJoAdmin:token');

api.defaults.headers.common.Authorization = `Bearer ${token}`;

export default api;

interface IResponse {
  text: 'Aberta' | 'Fechada' | 'Finalizada';
  color: 'green' | 'red' | 'navy';
}

export default function formatGroupStatus(
  available: boolean,
  closed: boolean,
): IResponse {
  if (closed) {
    return {
      text: 'Finalizada',
      color: 'navy',
    };
  }

  if (available) {
    return {
      text: 'Aberta',
      color: 'green',
    };
  }

  return {
    text: 'Fechada',
    color: 'red',
  };
}
